
import { Injectable } from "@angular/core";


@Injectable({
  providedIn: "root",
})
export class ApiConfiguration {

  //STAGING
  baseUrl = 'https://hubadminapi.malbro.bigcityvoucher.co.in/v1';


  login = this.baseUrl + "/hubadmin/log-in";


  getListProgram = this.baseUrl + "/hubadmin/list-programs";
  getAuthrizedOrderList = this.baseUrl + "/redemption/list-authentication-orders?";
  approveRejectOrder = this.baseUrl + "/redemption/update-client-status";
  orderListStats = this.baseUrl + "/redemption/statistics"
  getRejectionReason = this.baseUrl + '/redemption/rejection-reason'
  dashboardDetails = this.baseUrl + '/client/dashboard'


  //reports
  redemptionReport = this.baseUrl + '/report/redemption-report'
  catalogueReport = this.baseUrl + '/report/sku-report'
  loginReport = this.baseUrl + '/report/login-report'
  cancellationReport = this.baseUrl + '/report/cancellation-report'
  pointBalanceReport = this.baseUrl + '/report/pointbalance-report'
  deductionReport = this.baseUrl + '/report/deduction-report'

  //Finance
  getProformaInvoice = this.baseUrl + "/client/getperforma?";
  approveSingleInvoice = this.baseUrl + "/client/setapprovalstatus";
  approveServiceProfoma = this.baseUrl + "/finance/approve-service-profoma"
  downloadSingleInvoice = this.baseUrl + "/finance/proforma-download?";
  getInvoiceList = this.baseUrl + "/hubadmin/list-invoice?";
  uploadInvoiceStatus = this.baseUrl + "/client/setpaymentstatus";
  getServicePI = this.baseUrl + "/finance/list-service-profoma?service_pi_status=&invoice_flag="
  getServiceInvoicePI = this.baseUrl + "/finance/list-service-profoma?service_pi_status=2&invoice_flag=1"
  piForm = this.baseUrl + "/finance/list-proforma-form";
  uploadServicePayment = this.baseUrl + "/finance/upload-service-payment"
}
export interface ApiConfigurationInterface { }